<template>
  <div class="flex justify-center">
    <div class="c36">
      <MainLogo class="flex justify-center items-center my-s32" />

      <p class="c5"><span class="c14 c3">DIBBS 2005 FOOTBALL HOBBY BOX SWEEPSTAKES</span></p>
      <p class="c8"><span class="c14 c3">OFFICIAL RULES</span></p>
      <p class="c8"><span class="c3">Important: Please read these Official Rules before entering this Sweepstakes (the
          &ldquo;</span><span class="c3 c6">Sweepstakes</span><span class="c14 c3">&rdquo;).</span></p>
      <p class="c8"><span class="c14 c3">By participating in this Sweepstakes, you agree to be bound by these Official
          Rules
          and represent that you satisfy all of the eligibility requirements below.</span></p>
      <p class="c8 c11"><span class="c14 c3"></span></p>
      <p class="c10"><span class="c3 c14">NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN THIS
          SWEEPSTAKES.
          PURCHASE OR PAYMENT WILL NOT INCREASE ODDS OF WINNING.</span></p>
      <p class="c4"><span class="c14 c3"></span></p>
      <p class="c10"><span class="c12">Sweepstakes void where prohibited by law. Subject to applicable federal, state,
          and
          local laws. Capitalized terms not otherwise defined herein have the meanings given to them in the Dibbs User
          Agreement as may be amended from time to time, available at </span><span class="c17 c12"><router-link class="c13"
            to="/user-agreement">dibbsmarketplace.com/user-agreement</router-link></span><span
          class="c0">.</span></p>
      <p class="c4"><span class="c0"></span></p>
      <p class="c1"><span class="c3">1. Eligibility: </span><span class="c12">The Sweepstakes is open only to legal
          residents of the 50 United States or District of Columbia who are at least 18 years old (or the age of
          majority in
          their jurisdiction of residence, whichever is older) upon entry, and 1) access the Dibbs Platform using a
          dedicated QR code or referral code associated with the Sweepstakes; and 2) create a new Dibbs Account.
          Directors,
          officers, members, managers, and employees of Collectible Holdings Inc. (&ldquo;</span><span
          class="c3 c6">Sponsor</span><span class="c0">&rdquo;), and any of their respective parent companies, affiliate
          companies, subsidiaries, agents, professional advisors, advertising, promotional, public relations, and
          fulfillment agencies, consultants, legal counsel, website providers, web masters, and immediate family members
          (spouses, parent, child, sibling, grandparent, and &ldquo;step&rdquo; child, wherever they may live) of each
          are
          not eligible to win any prize. IF YOU DO NOT MEET ANY OF THESE REQUIREMENTS, OR ANY OTHER ELIGIBILITY
          REQUIREMENTS
          IN THESE OFFICIAL RULES, YOU ARE NOT ELIGIBLE TO WIN A PRIZE. To be eligible to win a prize, entries must be
          completed and received by Sponsor in the manner and format designated below.</span></p>
      <p class="c1 c11"><span class="c0"></span></p>
      <p class="c1"><span class="c3">2. Sweepstakes Period: </span><span class="c12">The Sweepstakes starts at 08:00:01
          a.m.
          ET on September 8, 2022, and ends at 5:00:00 p.m. ET on September 15, 2022 (the &ldquo;</span><span
          class="c3 c6">Sweepstakes Period</span><span class="c0">&rdquo;). All entries must be received during the
          Sweepstakes Period and meet other requirements in these Official Rules to be eligible to win a prize.
          Sponsor&rsquo;s or its designee&rsquo;s computer is the official time-keeping device for the
          Sweepstakes.</span>
      </p>
      <p class="c1 c11"><span class="c0"></span></p>
      <p class="c1"><span class="c3">3. How to Enter: </span><span class="c12">To enter, during the Sweepstakes Period,
          visit </span><span class="c17 c12"><router-link class="c13"
            to="/">dibbsmarketplace.com</router-link></span><span
          class="c0">&nbsp;and create a free Dibbs Account using your Sweepstakes referral code
          &quot;FOOTBALL&quot;.</span>
      </p>
      <p class="c10"><span class="c0">Upon completion of these steps, you will receive one entry into the Sweepstakes.
          Limit
          one entry per person during Sweepstakes Period. Any attempts to obtain additional entries, including through
          multiple or duplicate accounts will result in disqualification.</span></p>
      <p class="c18"><span class="c0">If participating in this Sweepstakes via your mobile device (which service may
          only be
          available via select devices and participating wireless carriers and is not required to enter), you may be
          charged
          for standard data use from your mobile device according to the terms in your wireless service provider&rsquo;s
          data plan. Normal airtime and carrier charges and other charges may apply to data use and will be billed on
          your
          wireless device bill or deducted from your pre-paid balance. Wireless carrier rates vary, so you should
          contact
          your wireless carrier for information on your specific data plan.</span></p>
      <p class="c1"><span class="c3">4. Identity of Entrant: </span><span class="c0">All entries must be submitted by
          the
          individual entrant. Bulk or automated entries will be disqualified (including entries made using any script,
          macro, bot, or promotional service). Multiple participants are not permitted to share the same email account.
          Any
          attempt to obtain additional entries through fraud or other illegitimate means will result in
          disqualification, at
          Sponsor&rsquo;s discretion.</span></p>
      <p class="c10"><span class="c12">If there is a dispute about the identity of the entrant, entries will be declared
          made by the authorized account holder of the email address submitted with the entry. An &ldquo;</span><span
          class="c3 c6">authorized account holder</span><span class="c0">&rdquo; means the natural person who is
          assigned to
          an e-mail address by an Internet access provider, online service provider or other organization (e.g.,
          business,
          educational institution, etc.) that is responsible for assigning e-mail addresses for the domain associated
          with
          the submitted e-mail address. A potential winner may be required to provide Sponsor with proof that a
          potential
          winner is the authorized account holder of the account associated with the winning entry.</span></p>
      <p class="c4"><span class="c0"></span></p>
      <p class="c1"><span class="c3">5. Selection and Notification of Winner: </span><span class="c0">On or about
          September
          15, 2022, Sponsor will select the name of one potential winner of the prize in a random drawing from among all
          eligible entries at Sponsor&rsquo;s headquarters as listed in Section 16 below. The odds of winning depend on
          the
          number of eligible entries received.</span></p>
      <p class="c10"><span class="c0">Potential winner will be notified via email on or about September 15, 2022. To the
          fullest extent permitted by applicable law, Sponsor may require each potential winner to sign and return,
          within
          seven days of being notified, an Affidavit of Eligibility and Liability/Publicity Release in order to claim
          his/her/their prize. If (i) the attempted notification is returned as undeliverable without a forwarding
          address;
          (ii) any required documents are not returned within seven days, such as the Affidavit of Eligibility and
          Liability/Publicity Release; or (iii) if potential winner is otherwise unable to accept the prize as stated,
          the
          prize will be forfeited and may be awarded to an alternate winner. Only three alternate drawings will be held
          for
          the prize, after which the prize will not be awarded. If, by reason of a print or other error, more prizes are
          claimed than the number set forth in these Official Rules, an alternate winner will be selected in accordance
          with
          the above selection process from among all eligible claimants making purportedly valid claims to award the
          prizes
          available.</span></p>
      <p class="c18"><span class="c12 c20">Verification of Potential Winner</span><span class="c0">: POTENTIAL WINNER IS
          SUBJECT TO VERIFICATION BY SPONSOR, WHOSE DECISIONS ARE FINAL AND BINDING IN ALL MATTERS RELATED TO THE
          SWEEPSTAKES. AN ENTRANT IS NOT A PRIZE WINNER UNLESS AND UNTIL ENTRANT&rsquo;S ELIGIBILITY HAS BEEN VERIFIED
          AND
          ENTRANT HAS BEEN NOTIFIED THAT VERIFICATION IS COMPLETE AND NO FURTHER ACTION IS NEEDED.</span></p>
      <p class="c1"><span class="c3">6. Prize: </span><span class="c0">One winner will receive one 2005 Topps Chrome
          Football hobby box. Approximate Retail Value: $1,500.00.</span></p>
      <p class="c1 c11"><span class="c0"></span></p>
      <p class="c1"><span class="c2">7. Prize Restrictions: </span><span class="c0">ALL FEDERAL, STATE AND LOCAL TAXES
          ASSOCIATED WITH THE RECEIPT OR USE OF ANY PRIZE ARE THE SOLE RESPONSIBILITY OF THE WINNER. Prizes are not
          transferable. No substitutions or exchanges of any prize (including for cash) will be permitted, except that
          Sponsor reserves the right to substitute a prize of comparable or greater value for any prize. Sponsor will
          not
          replace any lost or stolen prizes or prize components. Only the number of prizes stated in these Official
          Rules
          are available to be won in the Sweepstakes.</span></p>
      <p class="c10"><span class="c0">Except for applicable manufacturer&rsquo;s standard warranties, the prizes are
          awarded
          &ldquo;AS IS&rdquo; and WITHOUT WARRANTY OF ANY KIND, express or implied (including any implied warranty of
          merchantability or fitness for a particular purpose).</span></p>
      <p class="c4"><span class="c0"></span></p>
      <p class="c1"><span class="c3">8. Disclaimer: </span><span class="c12">Sponsor, and any of its respective parent
          companies, subsidiaries, affiliates, directors, officers, professional advisors, consultants, contractors,
          legal
          counsel, public relations firms, employees and advertising, fulfillment and marketing agencies (collectively,
          the
          &ldquo;</span><span class="c3 c6">Released Parties</span><span class="c0">&rdquo;) will not be responsible
          for:
          (a) any late, lost, misrouted, garbled or distorted or damaged transmissions or entries; (b) phone,
          electronic,
          hardware, software, network, Internet, or other computer or communications-related malfunctions or failures
          beyond
          Sponsor or the Released Parties&rsquo; reasonable control; (c) any Sweepstakes disruptions, injuries, losses
          or
          damages caused by events beyond the reasonable control of Sponsor or the Released Parties by non-authorized
          human
          intervention; or (d) any printing or typographical errors in any materials associated with the
          Sweepstakes.</span>
      </p>
      <p class="c1 c11"><span class="c0"></span></p>
      <p class="c1"><span class="c14 c3">9. Limitations of Liability; Releases: By entering the Sweepstakes, you release
          Sponsor and all Released Parties from any liability whatsoever, and waive any and all causes of action,
          related to
          any claims, costs, injuries, losses, or damages of any kind arising out of or in connection with the
          Sweepstakes
          or delivery, misdelivery, acceptance, possession, use of or inability to use any prize (including claims,
          costs,
          injuries, losses and damages related to rights of publicity or privacy, defamation or portrayal in a false
          light,
          whether intentional or unintentional), whether under a theory of contract, tort (including negligence),
          warranty
          or other theory.</span></p>
      <p class="c10"><span class="c0">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE SPONSOR OR
          THE
          RELEASED PARTIES BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING LOSS OF
          USE,
          LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE,
          ARISING OUT OF OR IN ANY WAY CONNECTED TO YOUR PARTICIPATION IN THE SWEEPSTAKES OR USE OR INABILITY TO USE ANY
          EQUIPMENT PROVIDED FOR USE IN THE SWEEPSTAKES OR ANY PRIZE, EVEN IF A RELEASED PARTY HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES.</span></p>
      <p class="c10"><span class="c0">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE AGGREGATE
          LIABILITY OF THE RELEASED PARTIES (JOINTLY) ARISING OUT OF OR RELATING TO YOUR PARTICIPATION IN THE
          SWEEPSTAKES OR
          USE OF OR INABILITY TO USE ANY EQUIPMENT PROVIDED FOR USE IN THE SWEEPSTAKES OR ANY PRIZE EXCEED $10. THE
          LIMITATIONS SET FORTH IN THIS SECTION WILL NOT EXCLUDE OR LIMIT LIABILITY FOR PERSONAL INJURY OR PROPERTY
          DAMAGE
          CAUSED BY PRODUCTS RENTED FROM THE SPONSOR, OR FOR THE RELEASED PARTIES&rsquo; GROSS NEGLIGENCE, INTENTIONAL
          MISCONDUCT, OR FOR FRAUD.</span></p>
      <p class="c4"><span class="c0"></span></p>
      <p class="c1"><span class="c3">10. Use of Name, Likeness, etc.: </span><span class="c0">To the fullest extent
          permitted by applicable law, entry into the Sweepstakes constitutes permission to use your name, hometown,
          aural
          and visual likeness and prize information for advertising, marketing, and promotional purposes without further
          permission or compensation (including in a public-facing winner list). As a condition of being awarded any
          prize,
          except where prohibited by law, winner may be required to execute a consent to the use of their name,
          hometown,
          aural and visual likeness and prize information for advertising, marketing, and promotional purposes without
          further permission or compensation. By entering this Sweepstakes, you consent to being contacted by Sponsor
          for
          any purpose in connection with this Sweepstakes.</span></p>
      <p class="c1 c11"><span class="c0"></span></p>
      <p class="c1"><span class="c3">11. Privacy: </span><span class="c12">See Sponsor&rsquo;s Privacy Policy at
        </span><span class="c17 c12"><router-link class="c13"
            to="/privacy-policy">dibbsmarketplace.com/privacy-policy</router-link></span><span
          class="c0">.</span></p>
      <p class="c1 c11"><span class="c0"></span></p>
      <p class="c1"><span class="c3">12. Winner List; Rules Request: </span><span class="c12">For a copy of the winner
          list,
          send a stamped, self-addressed, business-size envelope for arrival after September 15, 2022, and before
          September
          22, 2023, to the address listed in Section 16 below, Attn: DIBBS Sweepstakes Winner List. To obtain a copy of
          these Official Rules, visit </span><span class="c12 c17"><router-link class="c13"
            to="/sweepstakes-terms">dibbsmarketplace.com/sweepstakes-terms</router-link></span><span
          class="c0">. VT residents may omit return postage.</span></p>
      <p class="c1 c11"><span class="c0"></span></p>
      <p class="c1"><span class="c3">13. Intellectual Property Notice: </span><span class="c0">Dibbs is a trademark of
          Collectible Holdings Inc. The Sweepstakes and all accompanying materials are copyright &copy; 2022 by
          Collectible
          Holdings Inc. All rights reserved.</span></p>
      <p class="c1 c11"><span class="c0"></span></p>
      <p class="c1"><span class="c3">14. Disputes: </span><span class="c0">YOU AGREE THAT THE LAWS OF THE STATE OF NEW
          YORK,
          WITHOUT REGARD TO PRINCIPLES OF CONFLICT OF LAWS, WILL GOVERN THIS AGREEMENT AND ANY CLAIM OR DISPUTE THAT HAS
          ARISEN OR MAY ARISE BETWEEN YOU AND DIBBS RELATED TO THE SWEEPSTAKES, EXCEPT TO THE EXTENT GOVERNED BY FEDERAL
          LAW.</span></p>
      <p class="c1 c11"><span class="c0"></span></p>
      <p class="c1"><span class="c3">15. General Conditions: </span><span class="c0">Entrants agree to be bound by these
          Official Rules and by the decisions of Sponsor, which are final and binding in all respects. Sponsor reserves
          the
          right to change these Official Rules at any time, in its discretion, and to modify, suspend or cancel the
          Sweepstakes or any entrant&#39;s participation in the Sweepstakes should viruses, bugs, unauthorized human
          intervention, pandemics, public disruptions, or other causes beyond Sponsor&#39;s reasonable control affect
          the
          administration, security or proper play of the Sweepstakes, or Sponsor otherwise becomes (in its discretion)
          incapable of running the Sweepstakes as planned. Entrants who violate these Official Rules, violate any law,
          rule
          or regulation in connection with participation in the Sweepstakes, tamper with the operation of the
          Sweepstakes or
          engage in any conduct that is detrimental or unfair to Sponsor, the Sweepstakes or any other entrant (as
          determined in Sponsor&#39;s discretion) are subject to disqualification from the Sweepstakes. Sponsor reserves
          the
          right to lock out persons whose eligibility is in question or who have been disqualified or are otherwise
          ineligible to enter the Sweepstakes. Sponsor reserves the right to disqualify any entries received that
          contain
          inappropriate or offensive content. Sponsor&rsquo;s failure to enforce any term of these Official Rules will
          not
          constitute a waiver of that provision. Any provision of these Official Rules deemed unenforceable will be
          enforced
          to the extent permissible, and the remainder of these Official Rules will remain in effect. The use of the
          terms
          &ldquo;include&rdquo; or &ldquo;including&rdquo; in these Official Rules is illustrative and not
          limiting.</span>
      </p>
      <p class="c1 c11"><span class="c0"></span></p>
      <p class="c1"><span class="c3">16. Sponsor: </span><span class="c0">Collectible Holdings Inc., 129 Nevada St., El
          Segundo, CA 90245.</span></p>
      <p class="c1 c11"><span class="c0"></span></p>
      <p class="c16"><span class="c3">17. No Affiliation: </span><span class="c12">From time to time, Dibbs may promote
          this
          Sweepstakes using third-party websites or platforms, including without limitation social media platforms
          (&ldquo;Third Parties&rdquo;). Unless otherwise stated, Third Parties have no affiliation with Dibbs or the
          Sweepstakes.</span></p>
    </div>
  </div>
</template>

<script>
import MainLogo from '@/stories/misc/MainLogo.vue';

export default {
  name: 'UserAgreement',
  components: {
    MainLogo,
  },
  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'TERMS_AND_CONDITIONS_VIEWED',
      variables: {
        term: 'Sweepstakes',
      },
    });
  },
};
</script>

<style scoped>
.c36 {
  max-width: 720px;
  padding: 15pt;
}

.c4 {
  border-right-style: solid;
  padding-top: 12pt;
  border-top-width: 0pt;
  border-bottom-color: #e2e8f0;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #e2e8f0;
  padding-bottom: 0pt;
  line-height: 1.0;
  border-right-color: #e2e8f0;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #e2e8f0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0pt;
  height: 11pt
}

.c18 {
  border-right-style: solid;
  padding-top: 12pt;
  border-top-width: 0pt;
  border-bottom-color: #e2e8f0;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #e2e8f0;
  padding-bottom: 6pt;
  line-height: 1.0;
  border-right-color: #e2e8f0;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #e2e8f0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0pt
}

.c10 {
  border-right-style: solid;
  padding-top: 12pt;
  border-top-width: 0pt;
  border-bottom-color: #e2e8f0;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #e2e8f0;
  padding-bottom: 0pt;
  line-height: 1.0;
  border-right-color: #e2e8f0;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #e2e8f0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: left;
  padding-right: 0pt
}

.c5 {
  border-right-style: solid;
  padding-top: 0pt;
  border-top-width: 0pt;
  border-bottom-color: #e2e8f0;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #e2e8f0;
  padding-bottom: 0pt;
  line-height: 1.0;
  border-right-color: #e2e8f0;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #e2e8f0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: center;
  padding-right: 0pt
}

.c8 {
  border-right-style: solid;
  padding-top: 12pt;
  border-top-width: 0pt;
  border-bottom-color: #e2e8f0;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #e2e8f0;
  padding-bottom: 0pt;
  line-height: 1.0;
  border-right-color: #e2e8f0;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #e2e8f0;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: center;
  padding-right: 0pt
}

.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Times New Roman";
  font-style: normal
}

.c1 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left
}

.c16 {
  padding-top: 0pt;
  padding-bottom: 24pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left
}

.c14 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal
}

.c2 {
  text-decoration-skip-ink: none;
  font-family: "Times New Roman";
  -webkit-text-decoration-skip: none;
  font-weight: 700;
  text-decoration: underline
}

.c17 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
  text-decoration-skip-ink: none
}

.c20 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline
}

.c3 {
  font-weight: 700;
  font-family: "Times New Roman"
}

.c13 {
  color: inherit;
  text-decoration: inherit
}

.c12 {
  font-weight: 400;
  font-family: "Times New Roman"
}

.c11 {
  height: 11pt
}

.c6 {
  font-style: italic
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial"
}
</style>
